.section2 {
  padding: 0px 10px 40px 10px;
}

.section2Mobile {
  padding: 30px 20px 0px 20px;
}

.stripe {
  padding: 130px 30px 40px 80px;
  /* background: url("./assets/banners/stripe3.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; */
}

.stripeMobile {
  padding: 20px;
}

/* .bannerBottomStyle {
  background-image: url("./assets/banners/stripes.png");
  background-size: 11%;
  height: 150%;
} */

.section2BG {
  background-image: url("../assets/banners/section2BG.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 50px;
}

.mobileSec2BG {
  padding-bottom: 10px;
}

.textTitle2 {
  font-weight: 500 !important;
  font-size: 50px !important;
  line-height: 105% !important;
  padding-bottom: 3;
  text-transform: capitalize !important;
}

.mobileTextTitle2 {
  font-weight: 500 !important;
  font-size: 40px !important;
  line-height: 105% !important;
  padding-bottom: 20px;
  text-transform: capitalize !important;
}

.mobileTextTitle3 {
  font-weight: 500 !important;
  font-size: 30px !important;
  line-height: 105% !important;
  padding-bottom: 20px;
  text-transform: capitalize !important;
}

.mobileAbtImg {
  width: 100%;
  height: 70%;
  border-radius: 16px;
  object-fit: cover;
}

.abtImg {
  width: 105%;
  height: 80%;
  border-radius: 16px;
  /* object-fit: cover; */
  background-size: cover;
}

.mobileAbtDiv {
  width: 100%;
  height: 100%;
  padding: 20px 0px 10px 0px;
}

.abtDiv {
  width: 100%;
  height: 100%;
  padding: 140px 0px 50px 10px;
}

.stats {
  font-weight: 500 !important;
  font-size: 40px !important;
  text-transform: capitalize;
  font-style: italic;
  text-align: center;
}

.mobileStats {
  font-weight: 500 !important;
  font-size: 20px !important;
  text-transform: capitalize;
  font-style: italic;
  text-align: center;
}

/* we spread around the world */
.mobileStat {
  padding: 10px 30px 10px 30px;
  /* width: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* gap: 20px; */
}

.stat {
  padding: 10px 80px 40px 85px;
  width: 100%;
  display: flex !important;
  gap: 20px;
}

/* preserve */
.mobilePreserve {
  color: white;
  padding: 80px 30px 30px 30px;
}

.preserve {
  color: white;
  padding: 100px 80px 80px 80px;
}

/* clean card */
.mobileCleanCard {
  padding: 20px 30px 80px 30px;
}

.cleanCard {
  padding: 100px 80px 80px 80px;
}

/* padding div */
.mobileServ {
  padding: 80px 30px 50px 30px;
}

.serv {
  padding: 120px 30px 50px 80px;
  height: 100%;
}

/* contact us */
.mobileContactDiv {
  padding: 80px 30px 50px 30px;
}

.contactUsDiv {
  padding: 80px 30px 80px 80px;
}

/* text fields */
.mobileTextF {
  padding: 50px 30px 50px 30px;
}

.textField {
  padding: 180px 80px 80px 80px;
}

.cleanEnergy {
  position: relative !important;
  border-radius: 0 !important;
  padding: 20px !important;
  background-color: black !important;
}

.mobileCleanEnergy {
  position: relative !important;
  border-radius: 0 !important;
  padding: 20px;
  margin-bottom: 10px !important;
  background-color: black !important;
}

.cleanText {
  font-size: 20px;
}

.mobileCleanText {
  font-size: 15px;
  padding-left: 20px;
}

.contactCard {
  padding: 10px;
  background-color: black !important;
  color: white !important;
}

.mobileContactCard {
  border-radius: 0% !important;
  padding: 15px;
  background-color: transparent !important;
}

.contactStack {
  direction: row !important;
}

.mobileContactStack {
  justify-content: center !important;
  /* direction: row !important; */
}

.contactText {
  padding-left: 0%;
}

.mobileContactText {
  padding-left: 20px;
}
