@import url("https://fonts.googleapis.com/css2?family=Patua+One&family=Poppins:wght@400;500;700;900&family=Roboto:wght@100;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

/* .App {
  padding: 10px 40px 10px 40px;
} */

/* navigation bar
 */
.navbar.active {
  background: linear-gradient(rgba(0, 19, 3, 0.932), rgba(0, 12, 3, 0.918));
}

/* index page */
.banner {
  background: radial-gradient(rgba(20, 27, 27, 0.048), rgba(11, 32, 16, 0.918)),
    url("./assets/banners/banner2.jpg");

  /* height: 550px; */
  height: 765px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.stripe {
  padding: 100px 30px 20px 80px;
  /* background: url("./assets/banners/stripe3.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; */
}

.map {
  padding: 100px 110px 20px 80px;
  /* background: url("./assets/banners/stripe3.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; */
}

.mobileMap {
  padding: 20px 30px 20px 30px;
}

/* world map */
.mobileWorldMap {
  width: 100%;
  height: 100%;
  padding: 20px 0 0 0;
}

.worldMap {
  width: 100%;
  height: 100%;
  padding: 120px 0 0 0;
}

.mapData {
  background: url("./assets/banners/stripe3.png");
  height: 120px;
  padding: 40px 10px 40px 10px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.mobileMapData {
  background: url("./assets/banners/stripe3.png");
  height: 110px;
  padding: 40px 10px 40px 10px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.parallax {
  background: linear-gradient(rgba(25, 26, 26, 0.678), rgba(22, 23, 24, 0.753)),
    url("./assets/banners/parallax2.jpg");
  min-height: 500px;

  /* parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.heroText {
  padding: 250px 30px 20px 80px;
  color: white;

  
}

.mobileHeroText {
  padding: 140px 20px 20px 20px;
  text-align: center;
  color: white;


}

.indexStyle {
  font-size: 70px !important;
  text-decoration: none;
  font-family: "DM Sans", sans-serif !important;
  font-weight: 700 !important;
  line-height: 130% !important;
}

.indexTest {
  font-family: "DM Sans", sans-serif !important;
  color: white;
  font-size: 40px !important;
  font-weight: 700 !important;
  text-align: center;
}

.indexStyleYellow {
  font-size: 70px !important;
  text-decoration: none;
  font-family: "DM Sans", sans-serif !important;
  font-weight: 700 !important;
  line-height: 130% !important;
  color: yellow;
  padding-left: 18px;
}

.indexTestYellow {
  font-family: "DM Sans", sans-serif !important;
  color: white;
  font-size: 40px !important;
  font-weight: 700 !important;
  text-align: center;
  color: yellow;
}

.yellow {
  display: flex;
  direction: row;
}

.mobileYellow {
  display: block;
}

.contactUs {
  background: linear-gradient(rgba(25, 26, 26, 0.678), rgba(22, 23, 24, 0.753)),
    url("./assets/banners/banner.jpg");
  min-height: 500px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.footer {
  background-color: black;
  padding: 50px 130px 50px 130px;
}

.mobileFooter {
  background-color: black;
  padding: 50px 30px 50px 30px;
}

.mobileDiv {
  background-color: yellow;
  height: "1px";
}

.divFooter {
  background-color: yellow;
  width: 1px;
}

.mobileHeightSec {
  height: 200px;
}

.heightDef {
  height: 250px;
}

.mobileHeightServ {
  height: 150px;
}

.mobileHeightContact {
  height: 220px;
}
